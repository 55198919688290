<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="wrapper" ref="tab">
      <div class="cont" ref="tabWrapper">
        <div v-for="directory in directoryList" :key="directory.id" @click="getContent(directory.id)" :class="['item', activeItem === directory.id ? 'item-active' : '']" ref="tabitem">
          <p>{{ directory.name }}</p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-list">
        <div class="content-item" v-for="content in contentList" :key="content.id">
          <div class="content-item-left">
            <span :title="content.name" class="content-item-left-name">{{
              content.name
            }}</span>
          </div>
          <div class="content-item-right" @click="handleContent(content)">
            点击进入>
          </div>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="3"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import navRouter from '@/components/navRouter'
import back from '@/components/back'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from 'vuex'
import BScroll from 'better-scroll'
export default {
  name: 'CustomizeColumn',
  data() {
    return {
      icon: redIcon,
      title: '',
      activeItem: '',
      directoryList: [],
      contentList: [],
      id: null
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
    } else {
      this.icon = blueIcon
    }
    this.id = this.$route.query.id
    this.title = this.$route.query.title

    this.getData()
    this.$nextTick(() => {
      this.initScroll()
    })

    this.useRouterList.find((router, i) => {
      if (!router) return
      if (router.path === this.$route.fullPath) {
        this.useRouterList.splice(i + 1)
      }
    })
    this.setRouterList(this.useRouterList, this.$route.fullPath)
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
      } else {
        this.icon = blueIcon
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.POST(this.API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
        catId: [this.id]
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.directoryList = res.data.model
            if (this.directoryList.length >= 0) {
              this.getContent(this.directoryList[0].id)
            }
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },

    getContent(id) {
      this.activeItem = id
      this.POST(this.API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
        catId: [id]
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.contentList = res.data.model
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    handleContent(content) {
      this.$router.push({
        path: `/layout/customize/CONTENT`,
        query: { id: content.id }
      })

      // 保存下一级路径
      const path = `/layout/customize/CONTENT?id=${content.id}`
      this.useRouterList.push({
        name: content.name,
        path: path
      })
      this.setRouterList(this.useRouterList, path)
    },
    initScroll() {
      let width = 0
      for (let i = 0; i < this.directoryList.length; i++) {
        width += this.$refs.tabitem[i].getBoundingClientRect().width + 60 //getBoundingClientRect() 返回元素的大小及其相对于视口的位置
      }
      this.$refs.tabWrapper.style.width = (width + 60) / 100 + 'rem'
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.tab, {
            startX: 0,
            click: true,
            scrollX: true,
            scrollY: false,
            eventPassthrough: 'vertical'
          })
        } else {
          this.scroll.refresh()
        }
      })
    }
  },
  components: {
    menuPop,
    navRouter,
    back
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  .router-nav {
    width: 100%;
    height: 0.6rem;
    background-color: #ffffff;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .nav-left {
      display: flex;

      .router-name {
        font-size: 0.16rem;
        @include font_color('font-medium');
        margin-left: 0.1rem;

        .sub-router {
          font-size: 0.16rem;
          color: #cca766;
        }
      }
    }
  }

  .wrapper {
    width: 100vw;
    height: 1.6rem;
    overflow: hidden;
    white-space: nowrap; /*当子元素超过父元素宽度的时候，不会折行*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    @include background_color('background-medium');
    .cont {
      display: flex;
      align-items: center;
      font-size: 0.36rem;
      color: #ffd0d0;

      .item {
        margin-left: 0.6rem;
      }

      .item:last-child {
        margin-right: 0.6rem;
      }

      .item-active {
        font-size: 0.48rem;
        color: #ffffff;
      }
    }
  }

  .content {
    min-height: 22.2rem;
    padding: 0.4rem 0 0 0.4rem;
    box-sizing: border-box;
    @include background_color('background-normal');

    .content-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 0.6rem;
      max-height: 20rem;
      overflow: auto;
      .content-item {
        width: 100%;
        min-height: 0.8rem;
        background: #ffffff;
        border: 0.1rem solid rgba(180, 0, 0, 0.4);
        box-shadow: 0 0.03rem 0.06rem rgba(180, 0, 0, 0.2);
        border-radius: 0.05rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.78rem;
        padding-right: 1rem;
        margin-bottom: 0.6rem;

        .content-item-left {
          font-size: 0.48rem;
          color: #555555;
          display: flex;
          align-items: center;
          width: 80%;
          img {
            width: 1rem;
            height: 1rem;
            border-radius: 0.1rem;
            font-weight: bold;
            margin-right: 0.5rem;
          }
          .content-item-left-name {
            overflow: hidden;
            /*文本不会换行*/
            white-space: nowrap;
            /*当文本溢出包含元素时，以省略号表示超出的文本*/
            text-overflow: ellipsis;
          }
        }

        .content-item-right {
          font-weight: bold;
          font-size: 0.36rem;
          display: flex;
          align-items: center;

          @include font_color('font-medium');

          .click {
            color: #c3c3c3;
          }

          .exam-click {
            color: #c3c3c3;
            font-size: 0.3rem;
          }
        }
      }
    }
  }
}
</style>
